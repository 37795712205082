
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function wheelTireProductAttributesRT () {
    function scopeAttributeField1(prop, propIndex) {
        var attribute = prop.value;
        var field = prop.field.replace(/wheel_|tire_|_/g, ' ');
        return _createElement('div', {
            'key': field,
            'className': 'cm_wheel_tire_attributes_container product_' + field
        }, _createElement('label', { 'className': 'mb-0' }, field, ':'), _createElement('div', { 'className': 'cm_wheel_tire_attributes_values' }, !Array.isArray(attribute) ? _createElement('span', { 'key': '353' }, attribute) : null, Array.isArray(attribute) ? _createElement('span', { 'key': '418' }, attribute.join(', ')) : null));
    }
    function repeatProp2(prop, propIndex, attribute, field) {
        return scopeAttributeField1.apply(this, [
            prop,
            propIndex
        ]);
    }
    return _createElement.apply(this, [
        'div',
        { 'className': 'cm_wheel_tire_attributes' },
        _map(this.productData, repeatProp2.bind(this))
    ]);
}
        export const componentNames = []