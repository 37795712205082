
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function fitmentTableTabMobileRT () {
    return this.vehicleSpecific || this.manyFitments ? _createElement('a', {
        'className': 'toggleLink',
        'data-toggle': 'collapse',
        'href': '#fitmenttab_mobile',
        'key': '0'
    }, _createElement('span', { 'className': 'page-heading' }, window.themeSettings.locales.ymm.fitment_table_title || 'Fitment'), _createElement('svg', {
        'aria-hidden': 'true',
        'focusable': 'false',
        'role': 'presentation',
        'className': 'icon icon--wide icon-chevron-up',
        'viewBox': '0 0 498.98 284.49'
    }, _createElement('path', {
        'className': 'cls-1',
        'd': 'M579.91 486.24A35 35 0 0 1 520.16 511L330.42 321.25 140.68 511a35 35 0 1 1-49.5-49.5L305.67 247a35 35 0 0 1 49.5 0l214.49 214.5a34.89 34.89 0 0 1 10.25 24.74z',
        'transform': 'translate(-80.93 -236.76)',
        'fill': '#231f20'
    }))) : null;
}
        export const componentNames = []