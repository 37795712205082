
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function fitmentTableTabDesktopRT () {
    return this.vehicleSpecific || this.manyFitments ? _createElement('a', {
        'href': '#tab_fitmenttab',
        'className': 'tab-title',
        'role': 'tab',
        'data-toggle': 'tab',
        'aria-controls': 'tab_fitmenttab',
        'key': '0'
    }, '\n  ', window.themeSettings.locales.ymm.fitment_table_title || 'Fitment', '\n') : null;
}
        export const componentNames = []