
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function singleVehicleGarageRT () {
    return _createElement('div', { 'className': 'nav-action' }, _createElement('a', { 'className': 'cm_single-vehicle-garage' }, [this.changeButton(function () {
            return _createElement('div', {
                'aria-label': 'button',
                'className': 'cm_changeButton'
            }, _createElement('div', { 'className': 'garage-icon cm_icon_garage__background icon' }), ' \n      ', this.template === 'active' ? _createElement('div', {
                'className': 'cmTemplate_active',
                'key': '131'
            }, window.themeSettings.locales.ymm.select_block) : null, this.template === 'locked' ? _createElement('div', {
                'className': 'cmTemplate_locked',
                'key': '267'
            }, this.vehicleString) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], this.template === 'locked' ? _createElement('div', {
        'data-cm-role': 'go-to-results',
        'className': 'go-to-results cmTemplate_locked',
        'key': '896'
    }, _createElement('span', {}, '>')) : null, this.template === 'locked' ? _createElement('div', {
        'data-cm-role': 'discard-vehicle',
        'className': 'clear-garage cmTemplate_locked',
        'key': '1055'
    }, _createElement('span', {}, window.themeSettings.locales.clear_vehicle)) : null));
}
        export const componentNames = ["cm:changeButton"]